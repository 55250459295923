  import React from "react";
import {
  SimpleFormIterator,
  BooleanInput,
  DateInput,
  ArrayInput,
  NumberInput,
  TextInput,
  SelectInput,
  SelectArrayInput,
  RadioButtonGroupInput, ReferenceInput
} from 'react-admin';
  import PlayCircleFilledRounded from '@material-ui/icons/PlayCircleFilledRounded';
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";
import DesktopVersionSelectInput from "./DesktopVersionSelectInput";
import LanguageSelectInput from "./LanguageSelectInput";

  export const EstablishmentForm = () => {
  const translate = useTranslate();
  const establishmentOpions = [
    {id: 'always', name: 'always'},
    {id: 'never', name: 'never'},
    {id: 'by_exam', name: 'by_exam'},
  ];
  const establishmentGradeSharing = [
    {id: 'none', name: 'none'},
    {id: 'grade_only', name: 'grade_only'},
    {id: 'grade_and_copy', name: 'grade_and_copy'},
  ];

  return (
    <Box p="1em">
      <Box display="flex">

        <Box flex={2} mr="4em">
          <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
          <TextInput source="name" label='resources.establishments.fields.name' fullWidth />
          <TextInput source="description" label='resources.establishments.fields.description' fullWidth />
          <TextInput type="email" source="email" label='resources.establishments.fields.email' fullWidth />
          <TextInput source="customSupportPhoneNumber" label='resources.establishments.fields.customSupportPhoneNumber' fullWidth />
          <LanguageSelectInput source="establishmentLanguage" label='resources.establishments.fields.establishmentLanguage' fullWidth />
          <Typography variant="h4" gutterBottom>{translate("resources.establishments.fields.period.period")}</Typography>
          <TextInput source="period.name" label='resources.establishments.fields.period.name' fullWidth />
          <TextInput source="period.description" label='resources.establishments.fields.period.description' fullWidth />
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <DateInput source="period.startDate" label='resources.establishments.fields.period.startDate' fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <DateInput source="period.endDate" label='resources.establishments.fields.period.endDate' fullWidth />
            </Box>
          </Box>
          <BooleanInput source="period.current" label='resources.establishments.fields.period.current' options={{ checkedIcon: <PlayCircleFilledRounded /> }} fullWidth />
          <Typography variant="h4" gutterBottom>Mailing</Typography>
          <BooleanInput source="disableMailing" defaultValue={false} label='resources.establishments.fields.disableMailing' />
          <SelectArrayInput source="disabledMailings" choices={[
            { id: 'new-desktop-exam', name: 'new-desktop-exam' },
            { id: 'exam-expiration-reminder', name: 'exam-expiration-reminder' },
            { id: 'results-available', name: 'results-available' },
            { id: 'student-paper-sending-confirmation', name: 'student-paper-sending-confirmation' },
            { id: 'first-copies-ready-for-grading', name: 'first-copies-ready-for-grading' },
            { id: 'welcome', name: 'welcome' },
            { id: 'new-user-school', name: 'new-user-school' },
            { id: 'default-welcome', name: 'default-welcome' },
            { id: 'desktop-version-reminder', name: 'desktop-version-reminder' },
            { id: 'missing-copies-reminder', name: 'missing-copies-reminder' },
            { id: 'new-web-exam', name: 'new-web-exam' },
            { id: 'forgotten-password', name: 'forgotten-password' },
          ]} label='resources.establishments.fields.disabledMailings' fullWidth />
        </Box>

        <Box flex={1} ml="1em">
          <Typography variant="h4" gutterBottom>{translate("resources.establishments.fields.option")}</Typography>
          <BooleanInput source="learningGoalManaged" defaultValue={false} label='resources.establishments.fields.learningGoalManaged' />
          <SelectInput source="subjectExportPermissions" choices={[
            {id: 'none', name: 'Aucun'},
            {id: 'manager_only', name: 'Administrateur uniquement'},
            {id: 'teacher_only', name: 'Professeur uniquement'},
            {id: 'manager_and_teacher', name: 'Administrateur et professeur'},
          ]} label="resources.establishments.fields.subjectExportPermissions" fullWidth />
          <BooleanInput source="allowExportCopy" label='resources.establishments.fields.allowExportCopy' fullWidth />
          <Typography variant="h6" gutterBottom>{translate("resources.establishments.fields.gradeSharing")}</Typography>
          <SelectInput source="graderGradeSharing" choices={establishmentGradeSharing} label='resources.establishments.fields.graderGradeSharing' fullWidth />
          <SelectInput source="adminGradeSharing" choices={establishmentGradeSharing} label='resources.establishments.fields.adminGradeSharing' fullWidth />
          <BooleanInput source="allowProctoring" label='resources.establishments.fields.allowProctoring' fullWidth />
          <BooleanInput source="allowProctoringLive" label='resources.establishments.fields.allowProctoringLive' fullWidth />
          <BooleanInput source="allowProctoringAudioRecord" label='resources.establishments.fields.allowProctoringAudioRecord' fullWidth />
          <BooleanInput source="allowEnvironment" label='resources.establishments.fields.allowEnvironment' fullWidth />
          <BooleanInput source="durationFixed" label='resources.establishments.fields.durationFixed' fullWidth />
          <BooleanInput source="allowEarlyExitFromRemoteExam" label='resources.establishments.fields.allowEarlyExitFromRemoteExam' />
          <BooleanInput source="multipleGrading" label='resources.establishments.fields.multipleGrading' />
          <BooleanInput source="virusAlert" defaultValue={true} label='resources.establishments.fields.virusAlert' />
          <BooleanInput source="totalRamDetection" defaultValue={true} label='resources.establishments.fields.totalRamDetection' />
          <BooleanInput source="mandatoryTraining" defaultValue={true} parse={v => !v} format={v => !v} label='resources.establishments.fields.mandatoryTraining' />
          <BooleanInput source="webApp" defaultValue={false} label='resources.establishments.fields.webApp' />
          <BooleanInput source="remoteExam" defaultValue={false} label='resources.establishments.fields.remoteExam' />
          <NumberInput source="timeZeroSecond" label='resources.establishments.fields.timeZeroSecond' fullWidth />
          <NumberInput defaultValue={60} source="proctoringStorageDuration" label='resources.establishments.fields.proctoringStorageDuration' fullWidth />
          <ArrayInput source="fromEmails" label='resources.establishments.fields.fromEmails' fullWidth >
            <SimpleFormIterator>
              <SelectInput source="lang" choices={[
                { id: 'en', name: 'English' },
                { id: 'fr', name: 'French' },
                { id: 'es', name: 'Spanish' },
                { id: 'de', name: 'German' },
                { id: 'it', name: 'Italian' },
                { id: 'nl', name: 'Dutch' },
              ]} label='resources.establishments.fields.lang' fullWidth />
              <TextInput source="email" label='resources.establishments.fields.email' fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
          <RadioButtonGroupInput fullWidth source="noCorrectAnswer" label='resources.establishments.fields.noCorrectAnswer' choices={establishmentOpions} />
          <RadioButtonGroupInput fullWidth source="questionsPoints" label='resources.establishments.fields.questionsPoints' choices={establishmentOpions} />
          <RadioButtonGroupInput fullWidth source="timeOptions" label='resources.establishments.fields.timeOptions' choices={establishmentOpions} />
          <DesktopVersionSelectInput source="desktopTargetVersion" label='resources.establishments.fields.desktopTargetVersion' fullWidth />
          <DesktopVersionSelectInput source="desktopMinimalVersion" label='resources.establishments.fields.desktopMinimalVersion' fullWidth />
        </Box>
      </Box>
    </Box>
  )
}
