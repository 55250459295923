import React from "react";
import {
  TabbedShowLayout,
  Tab,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  ReferenceField,
  Pagination
} from 'react-admin'
import {
  ShowGuesser,
  FieldGuesser
} from "@api-platform/admin";
import { Typography } from '@material-ui/core';
import { useTranslate } from "react-admin";

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
const EstablishmentShow = props => {
  const translate = useTranslate();

  return (
    <ShowGuesser {...props}>
      <TabbedShowLayout>
        <Tab label="resources.general">
          <FieldGuesser source="name" addLabel={true}/>
          <FieldGuesser source="description" addLabel={true}/>
          <FieldGuesser source="email" addLabel={true}/>
          <FieldGuesser source="customSupportPhoneNumber" addLabel={true}/>
          <FieldGuesser source="establishmentLanguage" addLabel={true} />
          <FieldGuesser source="createdAt" addLabel={true}/>
          <FieldGuesser source="updatedAt" addLabel={true}/>
        </Tab>
        <Tab label="resources.establishments.fields.option">
          <FieldGuesser source="learningGoalManaged" addLabel={true}/>
          <FieldGuesser source="allowExportCopy" addLabel={true}/>
          <Typography variant="h6" gutterBottom>{translate("resources.establishments.fields.gradeSharing")}</Typography>
          <FieldGuesser source="graderGradeSharing" addLabel={true}/>
          <FieldGuesser source="adminGradeSharing" addLabel={true}/>
          <FieldGuesser source="allowProctoring" addLabel={true}/>
          <FieldGuesser source="allowProctoringLive" addLabel={true}/>
          <FieldGuesser source="allowProctoringAudioRecord" addLabel={true}/>
          <FieldGuesser source="allowEnvironment" addLabel={true}/>
          <FieldGuesser source="timeZeroSecond" addLabel={true}/>
          <FieldGuesser source="proctoringStorageDuration" addLabel={true}/>
          <FieldGuesser source="fromEmails" addLabel={true}/>
          <FieldGuesser source="durationFixed" addLabel={true}/>
          <FieldGuesser source="timeOptions" addLabel={true}/>
          <FieldGuesser source="questionsPoints" addLabel={true}/>
          <FieldGuesser source="allowEarlyExitFromRemoteExam" addLabel={true}/>
          <FieldGuesser source="desktopTargetVersion" addLabel={true}/>
          <FieldGuesser source="desktopMinimalVersion" addLabel={true}/>
        </Tab>
        <Tab label="Matières et classes" path="subjects">
          <ReferenceArrayField reference="subjects" source="subjects" addLabel={true} label="Matières">
            <SingleFieldList>
              <ChipField source="name"/>
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField reference="school-classes" source="classes" addLabel={true} label="Classes">
            <Datagrid>
              <TextField source="name"/>
              <ReferenceArrayField reference="subjects" source="subjects" addLabel={true} label="Matières">
                <SingleFieldList>
                  <ChipField source="name"/>
                </SingleFieldList>
              </ReferenceArrayField>
              <ReferenceField reference="academic-years" source="academicYear" addLabel={true} label="Année scolaire">
                <TextField source="name"/>
              </ReferenceField>
              <ShowButton/>
              <EditButton/>
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
        <Tab label="Élèves" path="students">
          <ReferenceArrayField reference="users" source="users" addLabel={false} pagination={<PostPagination />}>
            <Datagrid>
              <TextField source="firstName"/>
              <TextField source="lastName"/>
              <TextField source="email"/>
              <ShowButton/>
              <EditButton/>
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
      </TabbedShowLayout>
    </ShowGuesser>
  )
}

export default EstablishmentShow;
